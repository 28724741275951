/**
* Generated automatically at built-time (2025-01-02T07:23:02.466Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoor-kitchen-black-friday",templateKey: "sites/101-3b16f164-9c65-47e8-a147-8b1bdb78e282"};