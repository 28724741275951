/**
* Generated automatically at built-time (2025-01-02T07:23:02.159Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";
export default ({screen, siteContext})=>{
const helmet = mergeHelmet(siteContext.helmet, screen.helmet);
const pageId = "outdoor-kitchen-collection-overview";
return {[pageId]: {id: pageId,helmet,className: getComponentClassName(pageId),items: getScreenTemplates(screen)}};
}