/**
* Generated automatically at built-time (2025-01-02T07:23:02.285Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "maintenance-guide-outdoor-kitchen",templateKey: "sites/101-6d99e1d3-c341-4bf7-905f-8522fb2bba42"};